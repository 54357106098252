<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('crop_price_config.price_entry_status_change') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12" class="dam-form">
                                <b-overlay :show="loading">
                                    <ValidationObserver ref="formSearch"  v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(getCommodityList)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col lg="6" md="6" sm="12">
                                                    <ValidationProvider name="Division" vid="division_id">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="division_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('globalTrans.division') }}
                                                            </template>
                                                            <b-form-select
                                                            id="division_id"
                                                            plain
                                                            v-model="formData.division_id"
                                                            :options="divisionList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" md="6" sm="12">
                                                    <ValidationProvider name="District" vid="district_id">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="district_id"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            {{ $t('globalTrans.district') }}
                                                            </template>
                                                            <b-form-select
                                                            plain
                                                            v-model="formData.district_id"
                                                            :options="districtList"
                                                            id="district_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col :class="{ hide: formData.select_type ==='Weekly' }" lg="12" md="12" sm="12">
                                                    <b-row>
                                                        <b-col lg="6" sm="12">
                                                            <ValidationProvider name="Price Date" vid="price_date" rules="required">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="4"
                                                                    label-for="price_date"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{ $t('crop_price_config.price_date')}} <span class="text-danger">*</span>
                                                                </template>
                                                                    <b-form-input
                                                                    class="datepicker"
                                                                    v-model="formData.price_date"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    placeholder="yyyy-mm-dd"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                    <!-- <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                         <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('crop_price_config.price_entry_check_report_title') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row> -->
                                  <b-row v-if="Object.keys(marketsData).length">
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td>{{ $t('globalTrans.division') }}</td>
                                            <td>:</td>
                                            <td style="text-align: left" v-if="formData.division_id > 0">{{ getDivisionName(formData.division_id) }}</td>
                                            <td style="text-align: left" v-else>{{ $t('globalTrans.all') }}</td>

                                            <td>{{ $t('globalTrans.district') }}</td>
                                            <td>:</td>
                                            <td style="text-align: left" v-if="formData.district_id > 0">{{ getDistrictName(formData.district_id) }}</td>
                                            <td style="text-align: left" v-else>{{ $t('globalTrans.all') }}</td>

                                            <td>{{ $t('crop_price_config.price_date') }}</td>
                                            <td>:</td>
                                            <td style="text-align: left">{{ formData.price_date | dateFormat }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                    <table class="table table-sm table-bordered mt-2">
                                        <tr>
                                            <th class="text-center">{{ $t('globalTrans.district') }}</th>
                                            <th class="text-center">{{ $t('globalTrans.upazila') }}</th>
                                            <th class="text-center">{{ $t('crop_price_config.market_name') }}</th>
                                            <th class="text-center">{{ $t('globalTrans.action') }}</th>
                                        </tr>
                                        <template v-if="Object.keys(marketsData).length">
                                            <template v-for="item in marketsData">
                                                <template v-for="(item2, index2) in item">
                                                    <tr :key="index2">
                                                        <slot v-if="index2 == 0">
                                                            <td class="text-center" :rowspan="item.length">{{ getDistrictName(item2.district_id) }}</td>
                                                        </slot>
                                                        <td class="text-center">{{ getUpazilaName(item2.upazila_id) }}</td>
                                                        <td class="text-center"> {{ getMarketName(item2.market_id) }}</td>
                                                        <td class="text-center">
                                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Active" @click="toggleStatusCustom2(item2)"><i class="fa fa-toggle-off"></i></a>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td class="text-center" colspan="4">{{ $t('globalTrans.noDataFound') }}</td>
                                            </tr>
                                        </template>
                                        </table>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<style scoped>
.dam-form .is-valid, .is-invalid {
    background-image: none !important;
    padding-right: 0px !important;
}

.hide {
  visibility: hidden !important;
}
.error-body {
    background-color: red;
    color: white !important;
}
.error-body input {
    background-color: white !important;
    color: red !important;
}
.error-body .invalid-feedback {
    color: white !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
// import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import { dateFormat } from '@/Utils/fliter'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const today = new Date().toISOString().substr(0, 10)
// const today = '2023-01-01'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
    },
    data () {
        return {
            warehouseServiceBaseUrl: warehouseServiceBaseUrl,
            loading: false,
            is_weekly: false,
            saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.submit'),
            formData: {
                division_id: 0,
                district_id: 0,
                price_date: today
            },
            districtList: [],
            upazilaList: [],
            marketsData: [],
            processedDataList: [],
            headerExcelDefault: {
                orgName: null,
                orgNameBn: null,
                address: null,
                address_bn: null
            }
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', { defaultDate: 'today' })
    },
    computed: {
        headerValue: function () {
            const headerVal = []
            if (this.$i18n.locale === 'en') {
                    headerVal[0] = this.headerExcelDefault.orgName
                    headerVal[1] = this.headerExcelDefault.address
                    headerVal[2] = this.$t('crop_price_config.price_entry_check_report_title')
                    headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
                    headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
                    headerVal[5] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.formData.price_date)
                } else {
                    headerVal[0] = this.headerExcelDefault.orgNameBn
                    headerVal[1] = this.headerExcelDefault.address_bn
                    headerVal[2] = this.$t('crop_price_config.price_entry_check_report_title')
                    headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.formData.division_id)
                    headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.formData.district_id)
                    headerVal[5] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.formData.price_date)
                }
            return headerVal
        },
        excelData: function () {
            const excelData = []
            Object.values(this.marketsData).forEach(info => {
                info.forEach((info2, index2) => {
                    let farmer = {}
                        if (this.$i18n.locale === 'en') {
                            farmer = {
                            'District ': this.getDistrictName(info2.district_id),
                            'Upazila ': this.getUpazilaName(info2.upazila_id),
                            'Price Entry ': info2.price_date ? this.$t('globalTrans.yes') : this.$t('globalTrans.no'),
                            'Published ': info2.approve_status ? this.$t('globalTrans.yes') : this.$t('globalTrans.no')
                            }
                        } else {
                            farmer = {
                            'জেলা ': this.getDistrictName(info2.district_id),
                            'উপজেলা ': this.getUpazilaName(info2.upazila_id),
                            'মূল্যের যোগ হয়েছে কিনা ': info2.price_date ? this.$t('globalTrans.yes') : this.$t('globalTrans.no'),
                            'প্রকাশিত ': info2.approve_status ? this.$t('globalTrans.yes') : this.$t('globalTrans.no')
                            }
                        }
                        excelData.push(farmer)
                    })
                })
                return excelData
        },
        i18 () {
            return (this.$i18n.locale === 'bn') ? 1 : 0
        },
        divisionList: function () {
            return this.$store.state.agriMarketing.damData.divisionList
        }
    },
    watch: {
        'formData.division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        }
    },
    methods: {
        headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
        getDistrictList (divisionId = null) {
            return this.$store.state.agriMarketing.damData.districtList.filter(item => item.division_id === divisionId)
        },
        async getCommodityList () {
            this.loading = true
            const dataSearch = Object.assign({}, this.formData, { commodity_list: [] })
            const result = await RestApi.getData(agriMarketingServiceBaseUrl, 'crop-price-info/reports/price-report/get-price-entry-data', dataSearch)
            this.loading = false
            if (result.success) {
                this.marketsData = result.data
                this.headerDataExcel()
            }
        },
        getDivisionName  (divisionId) {
            if (divisionId) {
                const obj = this.divisionList.find(el => el.value === parseInt(divisionId))
                if (obj !== 'undefinded') {
                    return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            } else {
                return ''
            }
        },
        getDistrictName (districtId) {
            if (districtId) {
                const obj = this.$store.state.agriMarketing.damData.districtList.find(el => el.value === parseInt(districtId))
                if (obj !== 'undefinded') {
                    return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            } else {
                return ''
            }
        },
        getUpazilaName (upazilaId) {
            const obj = this.$store.state.agriMarketing.damData.upazilaList.find(el => el.value === parseInt(upazilaId))
            if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        },
        getMarketName (upazillaId = null) {
            const obj = this.$store.state.agriMarketing.commonObj.marketList.find(item => item.value === parseInt(upazillaId))
            if (obj !== undefined) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            }
        },
        pdfExport () {
          const reportTitle = this.$t('crop_price_config.price_entry_check_report_title')
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.marketsData, this, this.formData)
        },
        toggleStatusCustom2 (item) {
            window.vm.$swal({
            title: window.vm.$t('crop_price_config.price_entryToggle'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
            }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusCustom(item)
            }
            })
        },
        changeStatusCustom (item) {
            this.loading = true
            RestApi.deleteData(agriMarketingServiceBaseUrl, `${'crop-price-info/reports/price-report/change-status'}/${item.id}`).then(response => {
            if (response.success) {
                window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
                })
                this.getCommodityList()
            } else {
                window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
            }
            })
        }
    }
}
</script>
